<template>
  <div class="wrapper">
    <el-form :model="form" ref="form" label-width="110px" style="overflow-y:auto;overflow-x:hidden;">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="名称" prop="siteName">
            <el-input v-model="form.siteName" :disabled="true" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="建成时间" prop="completionTime">
            <el-date-picker
              v-model="form.completionTime"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :disabled="true"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="建设补贴时间" prop="constructionSubsidyTime">
            <el-date-picker
              v-model="form.constructionSubsidyTime"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :disabled="true"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="建设补贴金额" prop="constructionSubsidyAmount">
            <el-input v-model="form.constructionSubsidyAmount" :disabled="true" auto-complete="off">
              <template slot="append">万元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="运营补贴金额" prop="operatingSubsidyAmount">
            <el-input v-model="form.operatingSubsidyAmount" :disabled="true" auto-complete="off">
              <template slot="append">万元</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="状态" prop="siteStatus">
            <el-select v-model="form.siteStatus" placeholder="" :disabled="true" style="width: 100%">
              <el-option v-for="item in centStatusList" :key="item.dictPidVal" :label="item.dictDesc" :value="item.dictPidVal"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="25">
        <el-col :span="6">
          <el-form-item label="所属区域" prop="province">
            <el-select v-model="form.province" placeholder="" :disabled="true" style="width:100%;" @change="provinceChange">
              <el-option v-for="item in provinceList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="0" prop="belongCity">
            <el-select v-model="form.belongCity" placeholder="" :disabled="true" style="width:100%;" @change="cityChange">
              <el-option v-for="item in cityList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="0" prop="belognArea">
            <el-select v-model="form.belognArea" placeholder="" :disabled="true" style="width:100%;" @change="areaChange">
              <el-option v-for="item in areaList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label-width="0" prop="belongStreet">
            <el-select v-model="form.belongStreet" placeholder="" :disabled="true" style="width:100%;" @change="streetChange">
              <el-option v-for="item in streetList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label-width="0" prop="belongCm">
            <el-select v-model="form.belongCm" placeholder="" :disabled="true" style="width:100%;">
              <el-option v-for="item in communityList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="详细地址" prop="detailAddress">
            <el-input v-model="form.detailAddress" :disabled="true" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="性质" prop="nature">
            <el-select v-model="form.nature" placeholder="" :disabled="true" style="width:100%">
              <el-option v-for="item in natureList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="设施性质" prop="facilityNature">
            <el-select v-model="form.facilityNature" placeholder="" :disabled="true" style="width:100%">
              <el-option v-for="item in facilityNatureList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="运营企业" prop="operCompName">
            <el-input v-model="form.operCompName" :disabled="true" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="建设主体" prop="constructionSubject">
            <el-select v-model="form.constructionSubject" placeholder="" :disabled="true" style="width:100%">
              <el-option v-for="item in constructionSubjectList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="所属类型" prop="type">
            <el-select v-model="form.type" placeholder="" :disabled="true" style="width:100%">
              <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="场地来源" prop="venueSource">
            <el-select v-model="form.venueSource" placeholder="" :disabled="true" style="width:100%">
              <el-option v-for="item in venueSourceList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="面积" prop="areaSquare">
            <el-input v-model="form.areaSquare" placeholder="" :disabled="true" auto-complete="off">
              <template slot="append">平方米</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="负责人" prop="mainPersonName">
            <el-input v-model="form.mainPersonName" :disabled="true" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系方式" prop="mainPersonTel">
            <el-input v-model="form.mainPersonTel" :disabled="true" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="简介" prop="detailDesc">
            <el-input
              v-model="form.detailDesc"
              type="textarea"
              :rows="5"
              :disabled="true"
              maxlength="1500"
              show-word-limit
              auto-complete="off"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="状态" prop="siteStatusName">
            <div class="suninfoedit">
              <div class="suninfoeditul">
                <ul>
                  <li class="first active">
                    <span>建设中</span>
                  </li>
                  <li class="two active" v-if="form.siteStatusName === '已建成' || form.siteStatusName === '运营中' || form.siteStatusName === '停业中'">
                    <span>已建成</span>
                  </li>
                  <li class="two" v-else>
                    <span>已建成</span>
                  </li>                  
                  <li class="three active" v-if="form.siteStatusName == '运营中' || form.siteStatusName == '停业中'">
                    <span>运营中</span>
                  </li>
                   <li class="three" v-else>
                    <span>运营中</span>
                  </li>                  
                  <li class="four active" v-if="form.siteStatusName == '停业中'">
                    <span>停业中</span>
                  </li>
                  <li class="four" v-else>
                    <span>停业中</span>
                  </li>                  
                </ul>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>      
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="图片" prop="imgs">
            <upload-img :limit="6" :picDisable="true" :uploadLists="imgsList" @uploadChange="uploadSuccess2"></upload-img>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import api from '@/store/API/api'
import UploadImg from '@/components/UploadImg.vue'
import axios from 'axios'

export default {
  name: 'AddOrEdit',
  components: {
    UploadImg,
  },
  data() {
    return {
      centStatusList: [],
      natureList: [
        {
          value: '政府机构',
          label: '政府机构',
        },
        {
          value: '盈利机构',
          label: '盈利机构',
        },
        {
          value: '非营利机构',
          label: '非营利机构',
        },
      ],
      facilityNatureList: [
        {
          value: '政府资产',
          label: '政府资产',
        },
        {
          value: '自有资产',
          label: '自有资产',
        },
      ],
      constructionSubjectList: [
        {
          value: '政府',
          label: '政府',
        },
        {
          value: '国企',
          label: '国企',
        },
        {
          value: '企业',
          label: '企业',
        },
      ],
      typeList: [
        {
          value: '公建民营',
          label: '公建民营',
        },
        {
          value: '公办民营',
          label: '公办民营',
        },
        {
          value: '公办公营',
          label: '公办公营',
        },
        {
          value: '民办',
          label: '民办',
        },
      ],
      venueSourceList: [
        {
          value: '自有产权',
          label: '自有产权',
        },
        {
          value: '政府产权',
          label: '政府产权',
        },
        {
          value: '国企产权',
          label: '国企产权',
        },
        {
          value: '租赁',
          label: '租赁',
        },
      ],

      businessLicenseList: [],
      imgsList: [],
      headers: { Authorization: sessionStorage.token },
      uploadApi: process.env.NODE_ENV === 'development' ? '/api' + api.fileUpload : process.env.VUE_APP_BASEURL + api.fileUpload,
      form: {},
      fileList: [],
      communityList: [],
      tablePage: {
        totalResult: 0,
        currentPage: 0,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 200, 500, 1000],
        perfect: true,
      },
      addimageList: [],
      plateNoId: '',
      rules: {
        siteName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        nature: [{ required: true, message: '请选择性质', trigger: 'change' }],
        siteStatus: [{ required: true, message: '请选择状态', trigger: 'change' }],
        province: [{ required: true, message: '请选择省', trigger: 'change' }],
        belongCity: [{ required: true, message: '请选择市', trigger: 'change' }],
        belognArea: [{ required: true, message: '请选择市', trigger: 'change' }],
        belongStreet: [{ required: true, message: '请选择街道', trigger: 'change' }],
        belongCm: [{ required: true, message: '请选择社区', trigger: 'change' }],
        mainPersonTel: [{ pattern: /^(1[3456789]\d{9})$/, message: '请检查负责人联系方式格式' }],
      },
      provinceList: [],
      areaList: [],
      cityList: [],
      streetList: [],
      sexList: [
        {
          value: '男',
          label: '男',
        },
        {
          value: '女',
          label: '女',
        },
      ],
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
    searchist() {
      var list = []
      var _list = []
      var _this = this
      this.seqList.map(function(item) {
        if (item.plateNo.indexOf(_this.plateNoId) != -1) {
          //然后把当前json添加到list数组中
          _list.push(item)
          list = _list
        }
      })
      return list
    },
  },
  created() {
    this.initView()
    this.getDicList()
    this.getProvincesList()
  },
  methods: {
    ...mapActions([
      'addOrSaveServiceStation',
      'getCommunitysServe',
      'getProvincesServe',
      'getCitysServe',
      'getAreasServe',
      'getStreetsServe',
      'detailServiceStation',
      'getSysDictServe',
    ]),
    getProvincesList() {
      this.getProvincesServe().then((res) => {
        if (res.code == 200) {
          this.provinceList = res.data
        }
      })
    },
    provinceChange(val) {
      if (val) {
        this.getCitysList(val)
      }
    },
    streetChange(val) {
      if (val) {
        this.getCommunitysList(val)
      }
    },
    // 获取社区
    getCommunitysList(val) {
      let params = {
        parenName: val,
      }
      this.getCommunitysServe(params).then((res) => {
        if (res.code == 200) {
          this.communityList = res.data
        }
      })
    },
    // 获取市区
    getCitysList(val) {
      let params = {
        parenName: val,
      }
      this.getCitysServe(params).then((res) => {
        if (res.code == 200) {
          this.cityList = res.data
        }
      })
    },
    cityChange(val) {
      if (val) {
        this.getAreaList(val)
      }
    },
    // 获取区县list
    getAreaList(val) {
      let params = {
        parenName: val,
      }
      this.getAreasServe(params).then((res) => {
        if (res.code == 200) {
          this.areaList = res.data
        }
      })
    },
    areaChange(val) {
      if (val) {
        this.getStreetList(val)
      }
    },
    // 获取街道list
    getStreetList(val) {
      let params = {
        parenName: val,
      }
      this.getStreetsServe(params).then((res) => {
        if (res.code == 200) {
          this.streetList = res.data
        }
      })
    },
    getDicList() {
      this.getSysDictList('sunCenterStatus', 'centStatusList')
    },
    // 获取数据字典
    getSysDictList(code, codeList) {
      let params = {
        typeCode: code,
      }
      this.getSysDictServe(params).then((res) => {
        if (res.code == 200) {
          this[codeList] = res.data
        }
      })
    },
    handleAvatarSuccess(res, file, fileList) {
      if (res.code == 200) {
        this.$message.success('上传成功')
        this.addimageList = fileList.map((item) => {
          return item.response.data
        })
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
      if (fileList.length > 0) {
        this.addimageList = fileList.map((item) => {
          return item.response.data
        })
      } else {
        this.addimageList = []
      }
    },
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    //获取水泥车数据
    plateNoClick(item) {
      this.form.plateNoId = item.id
      this.plateNoId = item.plateNo
      this.$refs.xDown1.hidePanel()
    },
    getLeaveTime(data) {
      this.form.leaveTime = data
    },
    getFactTime(data) {
      this.form.factTime = data
    },
    initView() {
      this.addimageList = []
      this.itemData = JSON.parse(this.$route.query.item)
      let params = {
        id: this.itemData.id,
      }
      let data = null
      let server = 'detailServiceStation'
      this[server](params).then((res) => {
        if (res.code == 200) {
          data = res.data
          this.form = {
            siteName: data.siteName, // 名称
            completionTime: data.completionTime, // 建成时间
            constructionSubsidyTime: data.constructionSubsidyTime, // 建设补贴时间
            constructionSubsidyAmount: data.constructionSubsidyAmount, // 建设补贴金额
            operatingSubsidyAmount: data.operatingSubsidyAmount, // 运营补贴金额
            siteStatus: data.siteStatus, // 状态
            siteStatusName: data.siteStatusName, // 状态名称
            province: data.province, // 省
            belongCity: data.belongCity, // 市
            belognArea: data.belognArea, // 区域
            belongStreet: data.belongStreet, // 街道
            belongCm: data.belongCm, // 社区
            detailAddress: data.detailAddress, // 详细地址
            nature: data.nature, // 性质
            facilityNature: data.facilityNature, // 设施性质
            operCompName: data.operCompName, // 运营企业
            constructionSubject: data.constructionSubject, // 建设主体
            type: data.type, // 所属类型
            venueSource: data.venueSource, // 场地来源
            areaSquare: data.areaSquare, // 面积
            mainPersonName: data.mainPersonName, // 负责人
            mainPersonTel: data.mainPersonTel, // 联系方式
            detailDesc: data.detailDesc, //简介
            imgs: data.imgs, // 图片
            id: this.itemData.id,

            businessLicense: data.businessLicense,
          }
          this.getCitysList(data.province)
          if (data.belongCity) {
            this.getAreaList(data.belongCity)
          }
          if (data.belognArea) {
            this.getStreetList(data.belognArea)
          }
          if (data.belongStreet) {
            this.getCommunitysList(data.belongStreet)
          }
          if (data.imgs) {
            this.getFileDetail2(data.imgs)
          }
        }
      })
    },
    getFileDetail1(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        axios({
          method: 'get',
          data: {},
          url: '/api' + api.fileDetail + '/' + item,
          responseType: 'blob',
          headers: {
            Authorization: sessionStorage.token,
          },
        }).then((res) => {
          this.businessLicenseList.push({
            id: item,
            url: window.URL.createObjectURL(res.data),
          })
        })
      })
    },
    getFileDetail2(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        axios({
          method: 'get',
          data: {},
          url: '/api' + api.fileDetail + '/' + item,
          responseType: 'blob',
          headers: {
            Authorization: sessionStorage.token,
          },
        }).then((res) => {
          this.imgsList.push({
            id: item,
            url: window.URL.createObjectURL(res.data),
          })
        })
      })
    },
    uploadSuccess1(data) {
      this.form.businessLicense = data.join(',')
    },
    uploadSuccess2(data) {
      this.form.imgs = data.join(',')
    },
    //区域选择
    selectArea(data) {
      this.form.teamName = ''
      this.teamByAreaList = ''
      this.getTeamListByArea(data) //获取队伍
    }
  },
}
</script>
<style lang="scss" scoped>
.suninfoedit {
  background-color: white;
  width: 98%;
  padding-top: 27px;
  overflow: hidden;
}

.suninfoedit header,
.suninfoedit span {
  display: block;
  height: initial;
  padding: 0;
  width: 90%;
  color: #43aefc;
  font-size: 16px;
  margin-left: 5%;
}

.suninfoedit span {
  color: #5b6d7e;
}

.suninfoedit .suninfoeditul {
  margin: 50px 0 80px 0;
}

.suninfoedit ul {
  width: 60%;
  height: 9px;
  margin-left: 10%;
  font-size: 0;
  background-color: #eef6fb;
  position: relative;
}

.suninfoedit ul li {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #d9e2e8;
  position: absolute;
  top: 50%;
  margin-top: -7.5px;
}

.suninfoedit ul .two {
  left: 33.3333%;
  margin-left: -7.5px;
}

.suninfoedit ul .three {
  left: 66.6666%;
}

.suninfoedit ul .four {
  left: 100%;
}

.suninfoedit ul .active {
  background-color: #35a9fe;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px;
}

.suninfoedit ul li span {
  width: 56px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  position: absolute;
  left: 50%;
  margin-left: -28px;
  color: #5b6d7e;
  margin-top: 28px;
}

.suninfoedit .pic {
  width: 90%;
  margin: 20px auto 28px;
}

.suninfoedit .pic img {
  margin-right: 20px;
}
.wrapper {
  padding: 10px;
  overflow-y: auto;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }
  .my-dropdown1 {
    height: 200px;
    overflow: auto;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    padding: 0 8px;
    li {
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
  }
}
</style>
